export type Level = "debug" | "info" | "warn" | "error";

export type Log = {
  timestamp: number;
  level: Level;
  message: string;
  data: object;
  error?: Error;
};

export type Appender = (log: Log) => void;

export const createLogger = ({
  appenders = [consoleAppender],
  context = {}
}: {
  appenders?: Appender[];
  context?: object | (() => object);
} = {}) => {
  const values = (context: object | (() => object) = {}) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    typeof context === "function" ? context() : context;

  const logger = (context?: object | (() => object)) => {
    const log = (level: Level) => (message: string, data?: unknown) => {
      const log = {
        timestamp: Date.now(),
        level,
        message,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: {
          ...values(context),
          ...(data instanceof Error ? {} : typeof data === "object" ? data : {})
        },
        error: data instanceof Error ? data : undefined
      } satisfies Log;

      appenders.forEach(_ => _(log));
    };

    return {
      debug: log("debug"),
      info: log("info"),
      warn: log("warn"),
      error: log("error"),
      child: (next: object | (() => object)) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        logger(() => ({
          ...values(context),
          ...values(next)
        }))
    };
  };

  return logger(context);
};

export type Logger = ReturnType<typeof createLogger>;

export const consoleAppender: Appender = ({
  level,
  message,
  data,
  error
}: Log) => {
  console[level].apply(this, [
    message,
    ...(Object.values(data).length > 0 ? [data] : []),
    ...(error !== undefined ? [error] : [])
  ]);
};

const serializeError = ({ name, message, stack }: Error) =>
  ({ name, message, stack }) satisfies Error;

export const serializeLog = ({ error, ...log }: Log) =>
  ({ ...log, error: error ? serializeError(error) : undefined }) satisfies Log;
