export const delay = (timeout = 0) =>
  new Promise<void>(resolve => setTimeout(resolve, timeout));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = <F extends (...args: any[]) => any>(
  f: F,
  timeout: number
) => {
  let ready = true;
  let args: Parameters<F>;
  let result: ReturnType<F>;
  return (...a: Parameters<F>) => {
    args = a;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (!ready) return result;

    ready = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    result = f(...args);
    setTimeout(() => {
      ready = true;
    }, timeout);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => void>(
  f: F,
  delay: number
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => f(...args), delay);
  };
};

export const fork = (f: () => Promise<unknown>) => {
  void f();
};

export const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, k) => k + start);

export const subscriber = <T>() => {
  type Handler = (msg: T) => void;
  const handlers: Handler[] = [];

  const subscribe = (handler: Handler) => {
    handlers.push(handler);

    return {
      unsubscribe: () => {
        const index = handlers.indexOf(handler);
        if (index === -1) return;
        handlers.splice(index, 1);
      }
    };
  };

  const emit = (msg: T) => {
    for (const handler of handlers) handler(msg);
  };

  return {
    subscribe,
    emit
  };
};

export const capitalize = (value: string) =>
  value.charAt(0).toLocaleUpperCase() + value.slice(1);
