import { delay } from "@kha/common";
import type { Appender, Log } from "@kha/logging";
import { consoleAppender, createLogger, serializeLog } from "@kha/logging";
import type { IDBPTransaction } from "idb";
import { openDB } from "idb";
import Rollbar from "rollbar";
import { v4 as uuid } from "uuid";

import {
  env,
  logging,
  newRelicApiKey,
  rollbarToken,
  version
} from "./configuration";
import { ApplicationError } from "./core/error";
import { settings } from "./core/settings";
import { platform } from "./support/platform";

const { hostname, ip } =
  (typeof window !== "undefined" ? window.native : undefined) ?? {};

const instance = uuid();
const context = {
  instance,
  platform,
  hostname,
  ip,
  user: ""
};

type Context = typeof context;

let rollbar: Rollbar | undefined;

export const updateContext = (_: Partial<Context>) => {
  Object.assign(context, _);
  rollbar?.configure({
    payload: {
      person: {
        id: context.hostname ?? context.user
      }
    }
  });
};

const webWorker =
  typeof WorkerGlobalScope !== "undefined" && self instanceof WorkerGlobalScope;
const serviceWorker =
  typeof ServiceWorkerGlobalScope !== "undefined" &&
  self instanceof ServiceWorkerGlobalScope;

export const nativeAppender: Appender = serviceWorker
  ? () => undefined
  : webWorker
    ? log => self.postMessage({ type: "log", log })
    : log => window.native?.logging.log(log);

const createLogDatabase = () => {
  const push = async (log: Log) => {
    await transact(async transaction => {
      const logs = transaction.objectStore("logs");
      await logs.put(serializeLog(log));
    });
  };

  const pop = async () =>
    await transact(async transaction => {
      const logs = transaction.objectStore("logs");
      const [log] = (await logs.getAll(null, 1)) as (Log & {
        id: IDBValidKey;
      })[];
      if (!log) return;
      await logs.delete(log.id);
      return log;
    });

  const transact = async <T>(
    f: (_: IDBPTransaction<unknown, string[], "readwrite">) => Promise<T>
  ) => {
    const database = await openDB("logs", 1, {
      upgrade: database =>
        database.createObjectStore("logs", {
          keyPath: "id",
          autoIncrement: true
        })
    });
    const transaction = database.transaction(["logs"], "readwrite");
    const result = await f(transaction);
    await transaction.done;
    return result;
  };

  return { push, pop };
};

const remoteAppender: Appender = async (log: Log) => {
  if (settings.disableTelemetry) return;

  const { timestamp, level, message, data, error } = serializeLog(log);

  const event = {
    env,
    app: "command",
    version,
    timestamp,
    level,
    message,
    ...context,
    ...data,
    ...error
  };

  for (let i = 0; ; i++)
    try {
      await fetch(
        `https://log-api.newrelic.com/log/v1?Api-Key=${newRelicApiKey}`,
        {
          method: "POST",
          body: JSON.stringify(event)
        }
      );

      return;
    } catch (error) {
      if (i >= 7) throw error;
      await delay(100 * Math.pow(2, i));
    }
};

const database = createLogDatabase();

const databaseAppender: Appender = (log: Log) => {
  if (!logging) return;
  return database.push(log);
};

const runRemoteUpload = async () => {
  for (;;) {
    if (!navigator.onLine) {
      await delay(1000);
      continue;
    }
    const log = await database.pop();
    if (!log) {
      await delay(1000);
      continue;
    }

    try {
      remoteAppender(log);
    } catch {
      await database.push(log);
    }
  }
};

export const logger = createLogger({
  appenders: [consoleAppender, databaseAppender, nativeAppender]
});

export const initializeLogging = () => {
  rollbar = Rollbar.init({
    enabled: logging,
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkIgnore: (_, _2, payload: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const type = payload.body?.trace?.exception?.class;
      return type === "ApplicationError";
    },
    environment: env,
    payload: {
      client: {
        javascript: {
          code_version: version
        }
      },
      person: {
        id: hostname ?? null
      }
    }
  });

  const onError = (error: Error) => {
    if (error instanceof ApplicationError)
      logger.warn(`Application error: ${error.message}`, error);
    else logger.error("Error", error);
  };
  self.addEventListener("error", ({ error }: { error: Error }) =>
    onError(error)
  );
  self.addEventListener(
    "unhandledrejection",
    ({ reason: error }: { reason: Error }) => onError(error)
  );
};

if (typeof window !== "undefined") {
  window.native?.logging.subscribeLog(databaseAppender);
  void runRemoteUpload();
}
