export const save = (key: string, value: object) =>
  localStorage.setItem(key, JSON.stringify(value));

export const restore = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? "{}") as object;
  } catch {
    return {};
  }
};

export const loadDtedDirectoryHandle = ({
  userAction = false
}: { userAction?: boolean } = {}): Promise<
  FileSystemDirectoryHandle | undefined
> =>
  new Promise(resolve => {
    const request = indexedDB.open("dtedElevationData", 2);

    request.onupgradeneeded = () => {
      const db = request.result;
      db.createObjectStore("dtedElevationData", { keyPath: "id" });
    };

    request.onsuccess = () => {
      const db = request.result;
      db
        .transaction("dtedElevationData")
        .objectStore("dtedElevationData")
        .get(1).onsuccess = async event => {
        const { result } = event.target as IDBRequest<{
          handle: FileSystemDirectoryHandle;
        }>;
        const directoryHandle = result.handle;
        const permissionStatus = await directoryHandle.queryPermission();
        if (permissionStatus !== "granted" && userAction)
          await directoryHandle.requestPermission();
        resolve(directoryHandle);
      };
    };
  });

export const saveDtedDirectoryHandle = (
  directory: FileSystemDirectoryHandle
) => {
  const request = indexedDB.open("dtedElevationData", 2);

  request.onupgradeneeded = () => {
    const db = request.result;
    db.createObjectStore("dtedElevationData", { keyPath: "id" });
  };

  request.onsuccess = () => {
    const db = request.result;
    db.transaction(["dtedElevationData"], "readwrite")
      .objectStore("dtedElevationData")
      .put({ id: 1, handle: directory });
  };
};

export const clearDtedDirectoryHandle = () => {
  const request = indexedDB.open("dtedElevationData", 2);

  request.onupgradeneeded = () => {
    const db = request.result;
    db.createObjectStore("dtedElevationData", { keyPath: "id" });
  };

  request.onsuccess = () => {
    const db = request.result;
    db.transaction(["dtedElevationData"], "readwrite")
      .objectStore("dtedElevationData")
      .delete(1);
  };
};
