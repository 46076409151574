import { subscriber } from "@kha/common";
import * as z from "zod";

import { udpPorts, websocketEndpoints } from "../configuration";
import { restore, save } from "../persistence";
import {
  cueType,
  runway,
  savedConnectionConfiguration,
  type UdpConnectionConfiguration,
  type WebSocketConnectionConfiguration
} from "./model";
import { feetPerMeter } from "./units";

export const unitTypes = ["metric", "aviation", "military"] as const;
const unitType = z.enum(unitTypes);
export type UnitType = z.infer<typeof unitType>;

export const coordinateTypes = ["geographic", "MGRS"] as const;
const coordinateType = z.enum(coordinateTypes);
export type CoordinateType = z.infer<typeof coordinateType>;

export const mapTypes = [
  "Google",
  "Google Hybrid",
  "Mapbox",
  "Sectional Chart"
] as const;
const mapType = z.enum(mapTypes);
export type MapType = z.infer<typeof mapType>;

const aircraftSettings = z.object({
  name: z.optional(z.string()),
  viewOnly: z.optional(z.boolean()),
  cueable: z.optional(cueType),
  cotUid: z.optional(z.string()),
  cotType: z.optional(z.string()),
  cotVmf: z.optional(z.number()),
  cotCallsign: z.optional(z.string()),
  cotEndpoint: z.optional(z.string())
});

export type AircraftSettings = z.infer<typeof aircraftSettings>;

export const defaultAircraftSettings: AircraftSettings = {};

const tipAndCueSettings = z.object({
  enabled: z.boolean(),
  captureAltitude: z.number(),
  airspaceAltitude: z.number(),
  photo: z.object({
    forwardOverlap: z.number(),
    sideOverlap: z.number(),
    fov: z.number(),
    aspect: z.number()
  }),
  video: z.object({
    radius: z.number()
  })
});

export type TipAndCueSettings = z.infer<typeof tipAndCueSettings>;

const cameraMode = z.enum(["3d", "2d", "cockpit"]);
export type CameraMode = z.infer<typeof cameraMode>;

// WARNING: any changes made to the Settings data structure
// need to be backwards compatible with previously persisted data

const settingsType = z.object({
  aircraftSettings: z.record(z.string(), aircraftSettings),
  unitType,
  coordinateType,
  mapType,
  connections: z.array(savedConnectionConfiguration),
  disableTelemetry: z.boolean(),
  enableAdvanced: z.boolean(),
  disablePreflightChecklist: z.boolean(),
  enableCot: z.boolean(),
  enableCotVisualization: z.boolean(),
  enableCotBroadcast: z.boolean(),
  cotBroadcastInterval: z.number(),
  cotCallsign: z.string(),
  enableGamepad: z.boolean(),
  enableGamepadManual: z.boolean(),
  groundStationPressure: z.number(),
  runways: z.array(runway),
  tipAndCue: tipAndCueSettings,
  enableSunVisualization: z.boolean(),
  enableVesselVisualization: z.boolean(),
  enableEnduranceVisualization: z.boolean(),
  enableAdsbVisualization: z.boolean(),
  legacyHud: z.boolean(),
  cameraMode,
  dtedDataAvailable: z.boolean(),
  disableAnnouncements: z.boolean(),
  enableVoiceCommands: z.boolean()
});

export type Settings = z.infer<typeof settingsType>;

const defaultSettings: Settings = {
  aircraftSettings: {},
  unitType: "metric",
  coordinateType: "geographic",
  mapType: "Mapbox",
  connections: [
    ...udpPorts.map<UdpConnectionConfiguration>(port => ({
      type: "udp",
      port
    })),
    ...websocketEndpoints
      .split(",")
      .map(_ => _.trim())
      .filter(_ => !!_)
      .map<WebSocketConnectionConfiguration>(url => ({
        type: "websocket",
        url
      }))
  ],
  disableTelemetry: false,
  enableAdvanced: false,
  disablePreflightChecklist: false,
  enableCot: false,
  enableCotVisualization: false,
  enableCotBroadcast: false,
  cotBroadcastInterval: 1000,
  cotCallsign: "",
  enableGamepad: true,
  enableGamepadManual: false,
  groundStationPressure: 0,
  runways: [],
  tipAndCue: {
    enabled: false,
    captureAltitude: 5000 / feetPerMeter,
    airspaceAltitude: 5000 / feetPerMeter,
    photo: {
      forwardOverlap: 75,
      sideOverlap: 75,
      fov: 63,
      aspect: 0.75
    },
    video: {
      radius: 500
    }
  },
  enableSunVisualization: true,
  enableVesselVisualization: false,
  enableEnduranceVisualization: false,
  enableAdsbVisualization: true,
  legacyHud: false,
  cameraMode: "3d",
  dtedDataAvailable: false,
  disableAnnouncements: false,
  enableVoiceCommands: false
};

const { emit, subscribe } = subscriber<Settings>();
export const onSettingsUpdated = subscribe;

const loadSettings = () => ({
  ...defaultSettings,
  ...settingsType.partial().parse(restore("settings"))
});

export const saveSettings = (updates: Partial<Settings>) => {
  settings = { ...settings, ...updates };
  save("settings", settings);
  emit(settings);
};

export const saveAircraftSettings = (
  aircraftSerial: number,
  changes: Partial<AircraftSettings>
) => {
  const { aircraftSettings } = settings;
  saveSettings({
    aircraftSettings: {
      ...aircraftSettings,
      [aircraftSerial]: {
        ...defaultAircraftSettings,
        ...(aircraftSettings[aircraftSerial] ?? {}),
        ...changes
      }
    }
  });
};

export let settings = loadSettings();
